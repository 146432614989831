import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import requestMiddlewares from "../../requestMiddlewares";

export default async function loginWithGoogleOauth(dispatch: Dispatch): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('oauth', 'google/get_url').useBusinessManagerRoute()

  const response = await request.makeRequest();

  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
