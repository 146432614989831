import { FC } from 'react'
import {useIntl} from "react-intl";
import ComingSoon from "../../ComingSoon.tsx";
import {useTour} from "@reactour/tour";

const ToggleHelpDrawer: FC = () => {
  const intl = useIntl()
  const { setIsOpen } = useTour()

  return <button
    onClick={() => setIsOpen(true)}
    className='engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0'>
    {intl.formatMessage({ id: 'MENU.HELP' })}
  </button>
}

export {ToggleHelpDrawer}
