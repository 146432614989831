import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IPermission from "../../interfaces/IPermission";

const initialState: IPermission[] = []

const permissionsSlice = createSlice({
  name: EStores.PERMISSIONS,
  initialState,
  reducers: {
    setPermissions(state: IPermission[], data: PayloadAction<IPermission[]>) {
      return data.payload
    }
  },
});

export const {
  setPermissions,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;
