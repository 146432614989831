import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";
import {getSession} from "../../../utils/sessionStorage";
import IPager from "../../../interfaces/IPager";
import {selectCompany, setAll} from "../../../store/slices/companies";
import {GetSelectedCompany} from "../../../utils/selectCompany.ts";

export default async function (
  dispatch: Dispatch,
): Promise<IResponse<IPager<any>>> {
  const session = getSession()
  if (!session?.token) throw new Error('session has not defined');

  const request = new RACARequest('me', 'myCompanies').useBusinessManagerRoute();

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  if (response.data?.data) {
    dispatch(setAll(response.data.data));

    if (response.data.data.length === 0) return response;

    const cid = GetSelectedCompany();

    let company = response.data.data[0];

    response.data.data.forEach((c: any) => {
      if (c.id === cid) {
        company = c;
      }
    })

    dispatch(selectCompany(company));
  }

  return response;
}
