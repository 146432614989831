import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";
import {getSession} from "../../../utils/sessionStorage";
import {setMainRole, setUser} from "../../../store/slices/userMeSlice";
import {setPermissions} from "../../../store/slices/permissionsSlice";

export default async function getMyProfileRequest(
  dispatch: Dispatch,
): Promise<IResponse> {
  const session = getSession()
  if (!session?.token) throw new Error('session has not defined');

  const request = new RACARequest('me', 'myProfile').useBusinessManagerRoute();

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  if (response.data?.user) {
    dispatch(
      setUser(response.data.user)
    );

    dispatch(
      setMainRole(response.data.user.role)
    )
  }

  if (response.data?.permissions) {
    dispatch(
      setPermissions(response.data.permissions),
    );
  }

  return response;
}
