
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from "react-intl";
import Logo from "../../utils/Logo.tsx";

const AuthLayout = () => {
  const intl = useIntl()

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 vh-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10 w-100'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            {/*<a href='#' className='px-5' target='_blank'>*/}
            {/*  Terms*/}
            {/*</a>*/}

            {/*<a href='#' className='px-5' target='_blank'>*/}
            {/*  Plans*/}
            {/*</a>*/}

            <a href='mailto:info@racasf.com' className='px-5' target='_blank'>
              {intl.formatMessage({ id: 'AUTH.CONTACT_US' })}
            </a>
          </div>
        </div>
      </div>

      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('media/misc/auth-bg.png')})`}}
      >
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <Link to='/' className='mb-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('media/logos/logo-dark.png')}
              className='h-75px app-sidebar-logo-default'
            />
          </Link>

          {/*<img*/}
          {/*  className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'*/}
          {/*  src={toAbsoluteUrl('media/misc/auth-screens.png')}*/}
          {/*  alt=''*/}
          {/*/>*/}

          <div className='text-white fs-base text-center w-75'>
            {intl.formatMessage({ id: 'AUTH.WELCOME' })}
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
