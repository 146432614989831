const BASE = `https://ui-avatars.com/api/?background=666&color=fff&name={{NAME}}`.replace(' ', '+')

const BASE_RANDOM = `https://ui-avatars.com/api/?background=random&name={{NAME}}`.replace(' ', '+')

export default function (
  name: string,
  url: string | null,
  randomColor: boolean = false,
): string {
  const b = randomColor ? BASE_RANDOM : BASE

  name = name.replace(' ', '+')

  if (!url) return b.replace('{{NAME}}', name)

  if (url) return url

  return b.replace('{{NAME}}', `${name}`)
}
