import ISession from "../interfaces/ISession";

const KEY = `${import.meta.env.VITE_APP_SHORT_CODE}_session`

export const saveSession = (session: ISession) => {
  localStorage.setItem(KEY, btoa(JSON.stringify(session)))
}

export const getSession = (): ISession | null => {
  const data = localStorage.getItem(KEY)
  return data ? JSON.parse(atob(data)) : null;
}
