import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice.ts";
import {IResponse, RACARequest} from "../../../Request.ts";
import requestMiddlewares from "../../../requestMiddlewares.ts";
import {GetSelectedCompany} from "../../../../utils/selectCompany.ts";

export default async function(dispatch: Dispatch): Promise<IResponse> {
  const cid = GetSelectedCompany();
  if (!cid) {
    return {
      statusCode: 500,
      data: {
        message: 'No company selected'
      }
    }
  }

  dispatch(startLoading());

  const request = new RACARequest('dashboard', 'dashboard')
    .setPathValues('COMPANY_ID', cid)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
