/* eslint-disable @typescript-eslint/ban-ts-comment */
import {FC, useEffect, useRef} from 'react'
import {useThemeMode} from "../../../_metronic/partials";
import {KTIcon} from "../../../_metronic/helpers";
import {getCSSVariableValue} from "../../../_metronic/assets/ts/_utils";
import {number} from "yup";

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  total?: number
  available?: number
  pending?: number
}

const BalanceWidget: FC<Props> = ({
  className,
  chartSize = 70,
  chartLine = 10,
  chartRotate = 145,
  total,
  available,
  pending,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    refreshChart()
  }, [available, total, pending])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    let aprc = 0
    if (total && available) {
      aprc = available / total
    }

    initChart(chartSize, chartLine, chartRotate, aprc)
  }

  return (
    <div className={`card card-flush ${className}`} data-tour={'balance-widget'}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2'>{total?.toFixed(2)}</span>
            <span className='fs-4 fw-semibold text-gray-500 me-1 align-self-start'>€</span>
          </div>
          <span className='text-gray-500 pt-1 fw-semibold fs-6'>Current Balance</span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center me-5 pt-2'>
          <div
            id='kt_card_widget_17_chart'
            ref={chartRef}
            style={{
              minWidth: chartSize + 'px',
              minHeight: chartSize + 'px'
            }}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div>
        </div>

        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Available</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{available?.toFixed(2)} €</div>
          </div>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='bullet w-8px h-3px rounded-2 bg-warning me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Pending</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{pending?.toFixed(2)} €</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145,
  availablePrc: number = 0,
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  const options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  //@ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    //@ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)


  ctx?.translate(options.size / 2, options.size / 2) // change center
  ctx?.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  // Init 2
  drawCircle('#E4E6EF', options.lineWidth, 100 / 100)

  if (availablePrc < 1) {
    drawCircle(getCSSVariableValue('--bs-warning'), options.lineWidth, 0)
  }

  if (availablePrc > 0) {
    drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth, availablePrc)
  }
}

export {BalanceWidget}
