import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import * as Yup from "yup";
import {useIntl} from "react-intl";
import {useFormik} from "formik";

const initialValues = {
  password: '',
  passwordRepeat: '',
}

export default function () {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { search } = useLocation();

  const intl = useIntl()

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' })),
    passwordRepeat: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' }))
      .oneOf([Yup.ref('password')], intl.formatMessage({ id: 'VALIDATION.PASSWORDS_MUST_MATCH' }))
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
    },
  })

  return <div>
    <h1>Password Change</h1>
  </div>
}
