import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import ICodeSummary from "../../interfaces/ICodeSummary.ts";
import ICode from "../../interfaces/ICode.ts";


interface ICodeSlice {
  summary?: ICodeSummary;
  code?: ICode;
}

const initialState: ICodeSlice = {};

const code = createSlice({
  name: EStores.CODE,
  initialState,
  reducers: {
    setCode(state, action: PayloadAction<ICode>) {
      state.code = action.payload;
      return state;
    },
    setCodeSummary(state, action: PayloadAction<ICodeSummary>) {
      state.summary = action.payload;
      return state
    }
  },
});

export const {
  setCode,
  setCodeSummary,
} = code.actions;

export default code.reducer;
