import {IntlShape} from "react-intl";

export default function (intl: IntlShape) {
  return [
    // {
    //   selector: '[data-tour="company-selector"]',
    //   content: intl.formatMessage({id: 'TOUR.COMPANY_SELECTOR'}),
    //   stepInteraction: false,
    // }
  ]
}
