import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store.ts";
import {useIntl} from "react-intl";
import sweetalert2 from "sweetalert2";
import passwordRecoveryRequest from "../../../core/api/requests/auth/passwordRecoveryRequest.ts";
import ERoutes from "../../../core/enums/ERoutes.ts";

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const loading = useSelector((state: RootState) => state.loading)
  const dispatch = useDispatch()

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const intl = useIntl()
  const navigator = useNavigate()

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'VALIDATION.EMAIL_INVALID' }))
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN_3_CHARS' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX_50_CHARS' }))
      .required(intl.formatMessage({ id: 'VALIDATION.COMMON_REQUIRED' })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async ({ email }, {setStatus, setSubmitting}) => {
      await passwordRecoveryRequest(
        dispatch,
        {
          email,
        },
      );

      sweetalert2.fire(
        intl.formatMessage({ id: 'SWAL.EXCELLENT' }),
        intl.formatMessage({ id: 'SWAL.PASSWORD_RECOVERY_EMAIL_SENT' }),
        'success',
      ).then(() => {
        navigator(ERoutes.AUTH_LOGIN)
      })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-gray-900 fw-bolder mb-3'>{intl.formatMessage({ id: 'TITLES.FORGOT_PASSWORD' })}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({ id: 'FORGOT_PASSWORD.ENTER_YOUR_EMAIL' })}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {intl.formatMessage({ id: 'FORGOT_PASSWORD.ERROR_DETECTED' })}
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>{intl.formatMessage({ id: 'FORGOT_PASSWORD.SENT_PASSWORD_RESET_LINK' })}</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>
          {intl.formatMessage({ id: 'PLACEHOLDERS.EMAIL' })}
        </label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>{intl.formatMessage({ id: 'PLACEHOLDERS.SUBMIT' })}</span>
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({ id: 'PLACEHOLDERS.CANCEL' })}
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
