import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import ERoutes from "../../../../../app/core/enums/ERoutes.ts";
import {useEffect, useState} from "react";
import {IMenuContainer, ISubMenuItem} from "../../../../../app/core/interfaces/IMenuItem.ts";
import useMenu from "./useMenu.tsx";
import ComingSoon from "../../../../partials/ComingSoon.tsx";
import {ulid} from "ulid";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../app/core/store/store.ts";
import RCSelect from "../../../../../app/utils/RCSelect.tsx";
import {setSelected} from "../../../../../app/core/store/slices/searcher.ts";
import {selectCompany} from "../../../../../app/core/store/slices/companies.ts";

const MenuItem = ({ sm }: {sm: ISubMenuItem}) => {
  if (!sm.submenu || sm.submenu?.length === 0) return <SidebarMenuItem
    to={sm.redirectTo || ''}
    title={sm.name}
    soon={sm.soon}
    hasBullet={true}
  />

  return <SidebarMenuItemWithSub
    to={sm.redirectTo || ''}
    title={sm.name}
    soon={sm.soon}
    hasBullet={true}
  >
    {sm.submenu?.map((ssm) => {
      return <MenuItem key={ulid()} sm={ssm} />
    })}
  </SidebarMenuItemWithSub>
}

const SidebarMenuMain = () => {
  const intl = useIntl()
  const m = useMenu()
  const selectedCompany = useSelector((state: RootState) => state.companies)
  const dispatch = useDispatch()

  const [ menu, setMenu ] = useState<IMenuContainer[]>([])

  useEffect(() => {
    setMenu([...m])
    console.log('TODO: we need to filter menu...')
  }, [selectedCompany.selected]);

  const sc = (e: any) => {
    const company = selectedCompany.all.find((c) => c.id === e)
    if (!company) return

    dispatch(selectCompany(company))
    return
  }

  return (
    <>
      {selectedCompany?.all?.length > 1 &&
        <div className={'mt-3'} data-tour={'company-selector'}>
          <select className={'form-control form-control-solid form-select-sm'}
                  value={selectedCompany?.selected?.id || ''}
                  onChange={(e) => sc(e.target.value)}
          >
            <option key={ulid()} value={''}>{intl.formatMessage({id: 'PLACEHOLDERS.SELECT'})}</option>
            {selectedCompany.all.map((company) => (
              <option key={company.id} value={company.id}>{company.name}</option>
            ))
            }
          </select>
        </div>
      }

      {menu.map((menuItem) => {
        if (menuItem.hide) return null
        if (!selectedCompany.selected) return null

        return <div key={ulid()}>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {menuItem.name}
                {menuItem.soon && <ComingSoon className={'ms-2 float-end'} />}
              </span>
            </div>
          </div>

          <SidebarMenuItem
            to={ERoutes.DASHBOARD}
            icon='home'
            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          />

          {!menuItem.soon && menuItem.menu.map((mim) => {
            if (mim.hide) return null

            if (mim.submenu?.length === 0) {
              return <SidebarMenuItem
                key={ulid()}
                to={mim.redirectTo || ''}
                icon={mim.icon}
                title={mim.name}
                soon={mim.soon}
                fontIcon={mim.fontIcon}
              />
            }

            return <SidebarMenuItemWithSub
              to={mim.redirectTo || ''}
              icon={mim.icon}
              title={mim.name}
              soon={mim.soon}
              fontIcon={mim.fontIcon}
            >
              {mim.submenu?.map((sm) => {
                  if (sm.hide) return null
                  return <MenuItem key={ulid()} sm={sm} />
              })}
            </SidebarMenuItemWithSub>
          })}
        </div>
      })
      }
    </>
  )
}

export {SidebarMenuMain}
