import {toAbsoluteUrl} from "../helpers";
import Logo from "../../app/utils/Logo.tsx";
import {useIntl} from "react-intl";

export default function () {
  const intl = useIntl()

  return <div className="d-flex flex-column flex-center flex-column-fluid">
    <div className="d-flex flex-column flex-center text-center p-10">
      <div className="card card-flush w-lg-650px py-5">
        <div className="card-body py-15 py-lg-20">
          <div className="mb-13">
            <a href="index.html" className="">
              <Logo />
            </a>
          </div>
          <h1 className="fw-bolder text-gray-900 mb-7">{intl.formatMessage({ id: 'YOU_DONT_HAVE_ANY_COMPANY' })}</h1>
          <div className="fw-semibold fs-6 text-gray-500 mb-7">{intl.formatMessage({ id: 'REQUEST_YOUR_LEAD_TO_JOIN' })}</div>
          <div className="mb-n5">
            <img src={toAbsoluteUrl('media/auth/chart-graph.png')} className="mw-100 mh-300px theme-light-show" alt="" />
            <img src={toAbsoluteUrl('media/auth/chart-graph-dark.png')} className="mw-100 mh-300px theme-dark-show" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
}
