import React, {useEffect, useRef, FC} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from "../../../_metronic/partials";

type Props = {
  className: string
  data: {
    day: string
    label: string
    value: number
  }[]
}


const ScansGraph: FC<Props> = ({className, data}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const groups: string[] = []
    const categories: any = {
      '2024-09-06': {
        'success': 19,
        'ac_profit': 10
      }
    }
    const series: {[key: string]: number[]} = {}

    data.forEach((item) => {
      categories[item.day] = {
        ...categories[item.day],
        [item.label]: item.value
      }

      if (!groups.includes(item.label)) {
        groups.push(item.label)
      }
    })


    const cats = Object.keys(categories)

    const seriesData = groups.map((group) => {
      return {
        name: group,
        data: cats.map((cat) => categories[cat][group] || 0)
      }
    })

    const chart1 = new ApexCharts(chartRef.current, chart1Options(cats, seriesData))
    if (chart1) {
      chart1.render()
    }

    return chart1
  }

  useEffect(() => {
    const chart1 = refreshChart()

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, data])

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Code Scans</span>

          <span className='text-muted fw-semibold fs-7'>Last 30 days scans</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chart1Options = (cats: any, seriesData: any): ApexOptions => {
  return {
    series: seriesData,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      stacked: true,
      height: 350,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
    },
    legend: {
      position: 'right',
      offsetY: 40
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    stroke: {},
    xaxis: {
      categories: cats,
    },
  }
}

export {ScansGraph}
