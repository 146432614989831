import {configureStore} from "@reduxjs/toolkit";

import loading from "./slices/loadingSlice";
import session from "./slices/sessionSlice";
import permissions from "./slices/permissionsSlice";
import frontendUtilities from "./slices/futilsSlice";
import userMe from "./slices/userMeSlice";
import searcher from "./slices/searcher";
import companies from "./slices/companies";
import notifications from "./slices/notificationsSlice";
import modals from "./slices/modals";
import code from "./slices/code";

const store = configureStore({
    reducer: {
        loading,
        session,
        permissions,
        frontendUtilities,
        userMe,
        searcher,
        companies,
        notifications,
        modals,
        code,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
