import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import requestMiddlewares from "../../requestMiddlewares";
import {setSession} from "../../../store/slices/sessionSlice.ts";

export default async function loginWithGoogleOauthCallback(dispatch: Dispatch, code: string): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('oauth', 'google/callback').useBusinessManagerRoute()
    .setData({
      code,
      refreshToken: false,
      context: {},
    })

  const response = await request.makeRequest();

  requestMiddlewares(
    dispatch,
    response,
  );

  if (response.data) {
    dispatch(setSession(response.data))
  }

  dispatch(endLoading());

  return response;
}
