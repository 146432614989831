import {Suspense} from 'react'
import {createRoutesFromChildren, matchRoutes, Outlet, useLocation, useNavigationType} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {ThemeModeProvider} from '../_metronic/partials'
import LoadingContainer from "./utils/LoadingContainer.tsx";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2c51bacfac30270d3f2a8558f28d31b6@o4507958340550656.ingest.de.sentry.io/4507958342189136",
  // This enables automatic instrumentation (highly recommended)
  // If you only want to use custom instrumentation:
  // * Remove the BrowserTracing integration
  // * add Sentry.addTracingExtensions() above your Sentry.init() call
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  // For finer control of sent transactions you can adjust this value, or
  // use tracesSampler
  tracesSampleRate: 1.0,

  // Set tracePropagationTargets to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'api.dpass.app'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
              <LoadingContainer>
                <Outlet />
                <MasterInit />
              </LoadingContainer>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
