import {useIntl} from "react-intl";
import {IMenuContainer} from "../../../../../app/core/interfaces/IMenuItem.ts";
import ERoutes from "../../../../../app/core/enums/ERoutes.ts";

export default function (): IMenuContainer[] {
  const intl = useIntl()

  return [
    {
      name: '',
      soon: false,
      menu: [
        {
          name: intl.formatMessage({id: 'MENU.TEMPLATES'}),
          icon: 'office-bag',
          redirectTo: ERoutes.TEMPLATES,
          soon: true,
          submenu: []
        },
        {
          name: intl.formatMessage({id: 'MENU.CODES'}),
          icon: 'abstract-26',
          redirectTo: ERoutes.CODES,
          soon: false,
          submenu: []
        },
        {
          name: intl.formatMessage({id: 'MENU.CREDITS'}),
          icon: 'euro',
          redirectTo: ERoutes.CREDITS,
          soon: false,
          submenu: []
        },
        {
          name: intl.formatMessage({id: 'MENU.USERS'}),
          icon: 'profile-user',
          redirectTo: ERoutes.USERS,
          soon: true,
          hide: true,
          submenu: []
        },
        {
          name: intl.formatMessage({id: 'MENU.ROLES'}),
          icon: 'security-user',
          redirectTo: ERoutes.ROLES,
          hide: true,
          soon: true,
          submenu: []
        },
        {
          name: intl.formatMessage({id: 'MENU.MY_COMPANY'}),
          redirectTo: '#',
          icon: 'chart-line',
          soon: true,
          submenu: []
        }
      ].sort((a, b) => a.soon === b.soon ? 0 : a.soon ? 1 : -1)
    },
  ]
}
