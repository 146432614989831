import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {RootState} from "../core/store/store.ts";
import tourSteps from "./tourSteps.ts";
import {TourProvider} from "@reactour/tour";

export default function ({ children }: any) {
  const loading = useSelector((state: RootState) => state.loading)
  const intl = useIntl()

  return <TourProvider steps={tourSteps(intl)}>
    <div className={`w-100 h-100`}>
      <div
        style={{
          zIndex: 1000,
        }}
        className={`${loading ? 'd-flex' : 'd-none'} bg-secondary bg-opacity-50 justify-content-center align-items-center position-fixed w-100 h-100`}>
        <div className="spinner-border" style={{height: 40, width: 40}} role="status">
          <span className="sr-only">{intl.formatMessage({id: 'COMMON.PLEASE_WAIT'})}</span>
        </div>
      </div>
      {children}
    </div>
  </TourProvider>
}
