import {createSlice} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';

const initialState: boolean = false;

const loadingSlice = createSlice({
  name: EStores.LOADING,
  initialState,
  reducers: {
    startLoading(state: boolean) {
      return true;
    },
    endLoading(state: boolean) {
      return false;
    },
  },
});

export const {
  startLoading,
  endLoading,
} = loadingSlice.actions;

export default loadingSlice.reducer;
