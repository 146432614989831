
import {FC, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/core/store/store.ts";
import getPictureOrAvatar from "../../../../app/core/utils/getPictureOrAvatar.ts";
import {useIntl} from "react-intl";
import {logout} from "../../../../app/core/store/slices/sessionSlice.ts";
import ComingSoon from "../../ComingSoon.tsx";

const HeaderUserMenu: FC = () => {
  const session = useSelector((state: RootState) => state.session)
  const userMe = useSelector((state: RootState) => state.userMe)

  const dispatch = useDispatch()
  const intl = useIntl()

  const signOut = async () => {
    dispatch(logout())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={userMe?.profileImage} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {userMe?.user?.name} {userMe?.user?.lastName}
              {/*<span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{userMe?.mainRole?.name}</span>*/}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {userMe?.user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'#'} className='menu-link px-5'>
          {intl.formatMessage({ id: 'MENU.MY_PROFILE' })}
          <ComingSoon className={'ms-2'} />
        </Link>
      </div>

      {/*<div className='menu-item px-5'>*/}
      {/*  <a href='#' className='menu-link px-5'>*/}
      {/*    <span className='menu-text'>My Projects</span>*/}
      {/*    <span className='menu-badge'>*/}
      {/*      <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>*/}
      {/*    </span>*/}
      {/*  </a>*/}
      {/*</div>*/}

      {/*<div*/}
      {/*  className='menu-item px-5'*/}
      {/*  data-kt-menu-trigger='hover'*/}
      {/*  data-kt-menu-placement='left-start'*/}
      {/*  data-kt-menu-flip='bottom'*/}
      {/*>*/}
      {/*  <a href='#' className='menu-link px-5'>*/}
      {/*    <span className='menu-title'>My Subscription</span>*/}
      {/*    <span className='menu-arrow'></span>*/}
      {/*  </a>*/}

      {/*  <div className='menu-sub menu-sub-dropdown w-175px py-4'>*/}
      {/*    <div className='menu-item px-3'>*/}
      {/*      <a href='#' className='menu-link px-5'>*/}
      {/*        Referrals*/}
      {/*      </a>*/}
      {/*    </div>*/}

      {/*    <div className='menu-item px-3'>*/}
      {/*      <a href='#' className='menu-link px-5'>*/}
      {/*        Billing*/}
      {/*      </a>*/}
      {/*    </div>*/}

      {/*    <div className='menu-item px-3'>*/}
      {/*      <a href='#' className='menu-link px-5'>*/}
      {/*        Payments*/}
      {/*      </a>*/}
      {/*    </div>*/}

      {/*    <div className='menu-item px-3'>*/}
      {/*      <a href='#' className='menu-link d-flex flex-stack px-5'>*/}
      {/*        Statements*/}
      {/*        <i*/}
      {/*          className='fas fa-exclamation-circle ms-2 fs-7'*/}
      {/*          data-bs-toggle='tooltip'*/}
      {/*          title='View your statements'*/}
      {/*        ></i>*/}
      {/*      </a>*/}
      {/*    </div>*/}

      {/*    <div className='separator my-2'></div>*/}

      {/*    <div className='menu-item px-3'>*/}
      {/*      <div className='menu-content px-3'>*/}
      {/*        <label className='form-check form-switch form-check-custom form-check-solid'>*/}
      {/*          <input*/}
      {/*            className='form-check-input w-30px h-20px'*/}
      {/*            type='checkbox'*/}
      {/*            value='1'*/}
      {/*            defaultChecked={true}*/}
      {/*            name='notifications'*/}
      {/*          />*/}
      {/*          <span className='form-check-label text-muted fs-7'>Notifications</span>*/}
      {/*        </label>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className='menu-item px-5'>*/}
      {/*  <a href='#' className='menu-link px-5'>*/}
      {/*    My Statements*/}
      {/*  </a>*/}
      {/*</div>*/}

      <div className='separator my-2'></div>
      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='#' className='disabled menu-link px-5'>
          {intl.formatMessage({ id: 'MENU.ACCOUNT_SETTINGS' })}<ComingSoon />
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={signOut} className='menu-link px-5'>
          {intl.formatMessage({ id: 'COMMON.SIGN_OUT' })}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
