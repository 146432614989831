
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import loginRequest, {ILoginRequest} from "../../../core/api/requests/auth/loginRequest.ts";
import {useDispatch} from "react-redux";
import sweetalert2 from "sweetalert2";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes.ts";
import {getSession, saveSession} from "../../../core/utils/sessionStorage.ts";
import ERoutes from "../../../core/enums/ERoutes.ts";
import {setSession} from "../../../core/store/slices/sessionSlice.ts";
import {useIntl} from "react-intl";
import loginWithGoogleOauth from "../../../core/api/requests/auth/loginWithGoogleOauth.ts";
import {useQuery} from "react-query";
import loginWithGoogleOauthCallback from "../../../core/api/requests/auth/loginWithGoogleCallback.ts";

const initialValues = {
  email: '',
  password: '',
}

export function OAuth() {
  const dispatch = useDispatch()
  const params = useParams()

  const [ searchParams, setSearchParams ] = useSearchParams()

  const loginWithGoogle = async () => {
    const code = searchParams.get('code')
    if (!code) {
      window.location.replace(ERoutes.AUTH)
      return
    }

    const response = await loginWithGoogleOauthCallback(dispatch, code)

    if (response.statusCode === EHttpStatusCodes.OK) {
      saveSession(response.data)
      window.location.replace(ERoutes.DASHBOARD)
      return
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      )
    }
  }

  useEffect(() => {
    switch (params.provider) {
      case 'google':
        loginWithGoogle()
        break
      default:
        window.location.replace(ERoutes.AUTH)
    }
  }, []);

  return <div>
  </div>
}
