function getCompanyKey(): string {
  return `${import.meta.env.VITE_APP_SHORT_CODE}.selected.company`
}

export function SetSelectedCompany(companyId: string) {
  localStorage.setItem(getCompanyKey(), companyId)
}

export function GetSelectedCompany(): string | null{
  return localStorage.getItem(getCompanyKey())
}
