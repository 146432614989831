import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';

export enum EModals {
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  CREATE_CUSTOMER_CONTACT = 'CREATE_CUSTOMER_CONTACT',
}

export interface IModals {
  [EModals.CREATE_CUSTOMER]: boolean;
  [EModals.CREATE_CUSTOMER_CONTACT]: boolean;
}

const initialState: IModals = {
  [EModals.CREATE_CUSTOMER]: false,
  [EModals.CREATE_CUSTOMER_CONTACT]: false,
};

const modalsSlice = createSlice({
  name: EStores.MODALS,
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<EModals>) {
      state[action.payload] = true;
      return
    },
    closeModal(state, action: PayloadAction<EModals>) {
      state[action.payload] = false;
      return
    }
  },
});

export const {
  openModal,
  closeModal,
} = modalsSlice.actions;

export default modalsSlice.reducer;
