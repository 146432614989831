import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import INotification from "../../interfaces/INotification";

type Notifications = {
  notifications: INotification[]
}

const initialState: Notifications = {
  notifications: []
};

const slice = createSlice({
  name: EStores.NOTIFICATIONS,
  initialState,
  reducers: {
    setNotifications(state: Notifications, data: PayloadAction<INotification[]>) {
      return {
        ...state,
        notifications: data.payload,
      }
    },
  },
});

export const {
  setNotifications,
} = slice.actions;

export default slice.reducer;
