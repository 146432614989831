import {useIntl} from "react-intl";

export interface IComponent {
  className?: string
}

export default function (props: IComponent) {
  const intl = useIntl()

  return <div className={`${props.className} badge badge-secondary`}>
    {intl.formatMessage({ id: 'COMMON.SOON' })}
  </div>
}
